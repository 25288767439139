import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Loading from './Loading';
import { getPuzzles } from '../Api/api'
import { deletePuzzle } from '../Api/api'
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { faXmarkCircle } from '@fortawesome/pro-light-svg-icons'


const MyPuzzles = () => {

    const navigate = useNavigate()

    const [puzzles, setPuzzles] = useState([])

    const [isError, setIsError] = useState(false)

    const [isLoading, setIsLoading] = useState(true)

    const [isDeleteButtonClicked, setIsDeleteButtonClicked] = useState(false)

    const [deletePuzzleStatus, setDeletePuzzleStatus] = useState(null)


    useEffect(() => {
        fetchPuzzles()
    }, [])


    const handleDeletePuzzle = (puzzleId) => {
        setDeletePuzzleStatus("CLICKED")
        deletePuzzle(puzzleId, (result) => {
            if (result.success) {
                setDeletePuzzleStatus("DELETE_SUCCESS")
                // Get the updated version of the users puzzles
                setTimeout(() => {
                    setIsDeleteButtonClicked(false)
                    setDeletePuzzleStatus(null)
                    fetchPuzzles()
                }, 3000);
            } else {
                setDeletePuzzleStatus("DELETE_FAILURE")
            }
        })
    }

    const fetchPuzzles = () => {
        getPuzzles((result) => {
            if (result.success) {
                setPuzzles(result.data)
            } else {
                setIsError(true)
            }
            setIsLoading(false)
        })
    }

    const getBackgroundColor = (puzzleStatus) => {
        if (puzzleStatus === "QUEUED") {
            return "gold"
        } else if (puzzleStatus === "REJECTED") {
            return "#fd5c63"
        } else if (puzzleStatus === "SUBMITTED") {
            return "#50C878"
        } else if (puzzleStatus === "PLAYED") {
            return "#72A0C1"
        } else if (puzzleStatus === "ACTIVE") {
            return "#FF69B4"
        }
    }

    const options = {
        month: "long",
        day: "numeric",
        year: "numeric"
    };

    return isLoading ? <>
        <Loading />
    </>
        : isError ? <>
            <Navbar />
            <div className='error'>
                There was an error getting your puzzles.
                Please reload and try again.
            </div>
            <Footer />
        </>
            : <>
                <Navbar />
                <div className='grid-center'>
                    <div style={{ textAlign: "center", marginTop: 100 }}>
                        <p className='instructions-header-edit'>
                            My Puzzles
                        </p>
                        {puzzles.length > 0 ?
                            <>
                                <p className='edit-puzzle-text'>
                                    Remember, you can only edit your puzzle when it's status is SUBMITTED.
                                </p>
                                <p
                                    style={{ marginTop: -10 }}
                                    className='edit-puzzle-text'>
                                    Once your puzzle is Queued, it is no longer editable. But you can delete your puzzle at any time.
                                </p>
                            </> : null}
                    </div>
                    {puzzles.length === 0 ?
                        <div
                            style={{ marginTop: 30 }}
                            className='instructions-container'>
                            <p className='instructions-header-edit'>
                                You have not made any puzzles yet.
                            </p>
                            <p
                                onClick={() => navigate("/create")}
                                className='edit-puzzle-text link'>
                                Click here to make your first puzzle!
                            </p>
                        </div> :
                        puzzles.map((puzzle, index) => {
                            return <div className='flex-center'>
                                <div
                                    key={puzzle.createdAt._seconds}
                                    style={{ marginTop: 25, marginBottom: 50, paddingBottom: 50 }}
                                    className='instructions-container'>

                                    <p className='riddle-word'>
                                        {puzzle.riddleWord.toUpperCase()}
                                    </p>

                                    <p className='author-name'>by {puzzle.puzzleAuthor}</p>
                                    <div
                                        className='my-puzzle-container-flex'>
                                        <button
                                            style={{ cursor: "default", boxShadow: "none", backgroundColor: getBackgroundColor(puzzle.puzzleStatus), border: "none" }}
                                            className='puzzle-action'>
                                            Status: {puzzle.puzzleStatus}
                                        </button>
                                        <button
                                            onClick={() => navigate(`/puzzle/${puzzle.puzzleId}`)}
                                            className='puzzle-action'>
                                            {puzzle.puzzleStatus === "SUBMITTED" ? "Edit Puzzle" : "View Puzzle"}
                                        </button>
                                        <button
                                            onClick={() => puzzle.puzzleStatus === "ACTIVE" || puzzle.puzzleStatus === "PLAYED" ? null : setIsDeleteButtonClicked(index)}
                                            className={puzzle.puzzleStatus === "ACTIVE" || puzzle.puzzleStatus === "PLAYED" ? 'puzzle-action-restricted puzzle-action' : 'puzzle-action'}>
                                            Delete Puzzle
                                        </button>
                                    </div>
                                    {isDeleteButtonClicked === index ?
                                        deletePuzzleStatus === "DELETE_FAILURE" ?
                                            <div>
                                                <FontAwesomeIcon
                                                    style={{ marginTop: 30 }}
                                                    className="xmark-icon" icon={faXmarkCircle} />
                                                <p style={{ fontSize: 18 }}>
                                                    There was an error deleting your puzzle. Please reload and try again.
                                                </p>
                                            </div>
                                            :
                                            deletePuzzleStatus === "DELETE_SUCCESS" ?
                                                <div>
                                                    <FontAwesomeIcon
                                                        style={{ marginTop: 30, marginBottom: -10 }}
                                                        className="checkmark-icon" icon={faCheckCircle} />
                                                    <p style={{ fontSize: 18 }}>
                                                        Your puzzle was successfully deleted!
                                                    </p>
                                                </div>
                                                :
                                                <div className='delete-button-container'>
                                                    <p>Are you sure you want to delete this puzzle?</p>
                                                    <div
                                                        style={{ gap: 50 }}
                                                        className='flex-center'>
                                                        <button
                                                            onClick={() => setIsDeleteButtonClicked(null)}
                                                            className='delete-puzzle-button cancel'>
                                                            No, cancel
                                                        </button>
                                                        <button
                                                            onClick={() => handleDeletePuzzle(puzzle.puzzleId)}
                                                            className='delete-puzzle-button delete'>
                                                            {deletePuzzleStatus === "CLICKED" ? "Deleting..." : "Yes, delete"}
                                                        </button>
                                                    </div>
                                                </div> : null}
                                </div>
                            </div>
                        }).sort((a, b) => a.key - b.key)
                    }
                </div >
                <Footer />
            </>
}

export default MyPuzzles