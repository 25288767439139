import { getAuth, onAuthStateChanged } from "firebase/auth";
const { API_ROOT_URL } = require("../../../configs/activebuildconfig.json")

/**
 * CONTROLLER - users
 * FUNCTION - getPuzzle
 * VERIFY - verified user
 * @param {*} puzzleId The id of the puzzle
 * @param {*} callback callback function
 * @returns The puzzle data 
 */

export const getPuzzle = async (puzzleId, callback) => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken(false);
        const response = await fetch(`${API_ROOT_URL}/users/get_puzzle`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
            },
            body: JSON.stringify({
                token,
                puzzleId
            }),
        })
        const result = await response.json();
        callback(result)
    })
}