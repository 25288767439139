import React, { useState, useContext, useEffect } from 'react';
import { createPuzzle } from '../Api/api';
import { useNavigate } from "react-router-dom"
import { isMobile } from 'react-device-detect';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Clue from './Clue';
import Navbar from './Navbar'
import Footer from './Footer';
import { ContextProvider } from '../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation, faAsterisk, faCheckCircle, faXmarkCircle } from "@fortawesome/pro-regular-svg-icons";
import "../Styles/createGame.css"

const CreatePuzzle = () => {

    const navigate = useNavigate()

    const { setStateOrLocalStorage } = useContext(ContextProvider)

    const [question, setQuestion] = useState(setStateOrLocalStorage("", "question"))

    const [riddleWord, setRiddleWord] = useState(setStateOrLocalStorage("", "riddleWord"))

    const [isBoxChecked, setIsBoxChecked] = useState(false)

    const [puzzleAuthor, setPuzzleAuthor] = useState(setStateOrLocalStorage("", "puzzleAuthor"))

    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false)

    const [isPuzzleSuccessfullySubmitted, setIsPuzzleSuccessfullySubmitted] = useState(null);

    const [timeLeft, setTimeLeft] = useState(null)

    const [clues, setClues] = useState(setStateOrLocalStorage(
        {
            "id-0": { clueText: "", clueDescription: "" },
            "id-1": { clueText: "", clueDescription: "" },
            "id-2": { clueText: "", clueDescription: "" },
            "id-3": { clueText: "", clueDescription: "" },
            "id-4": { clueText: "", clueDescription: "" },
            "id-5": { clueText: "", clueDescription: "" },
            "id-6": { clueText: "", clueDescription: "" },
            "id-7": { clueText: "", clueDescription: "" },
            "id-8": { clueText: "", clueDescription: "" },
            "id-9": { clueText: "", clueDescription: "" },
            "id-10": { clueText: "", clueDescription: "" },
            "id-11": { clueText: "", clueDescription: "" },
        }, "clues"))


    const [clueIndexes, setClueIndexes] = useState([
        "id-0",
        "id-1",
        "id-2",
        "id-3",
        "id-4",
        "id-5",
        "id-6",
        "id-7",
        "id-8",
        "id-9",
        "id-10",
        "id-11",
    ])

    const allowedRiddleWordCharacters = ["KeyA", "KeyB", "KeyC", "KeyD", "KeyE",
        "KeyF", "KeyG", "KeyH", "KeyI", "KeyJ", "KeyK", "KeyL", "KeyM", "KeyN", "KeyO", "KeyP", "KeyQ", "KeyR",
        "KeyS", "KeyT", "KeyU", "KeyV", "KeyW", "KeyX", "KeyY", "KeyZ", "Backspace", "ArrowLeft", "ArrowRight"]

    useEffect(() => {
        setTimeout(() => {
            if (timeLeft && timeLeft > 0) {
                setTimeLeft(timeLeft - 1)
            }
        }, 1000);
    }, [timeLeft])

    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    const setLocalStorage = (id, value) => {
        localStorage.setItem(id, JSON.stringify(value))
    }

    const handleSetClue = (clueIndex, newData, clueType) => {
        clueType === "TEXT" ? handleSetClueText(clueIndex, newData) :
            handleSetClueDescription(clueIndex, newData)
    }

    const handleSetClueText = (clueIndex, newClueText) => {
        // The clueIndex is the position on the board that the clue is at
        // Use that to get the actual index position from clueIndexes
        clues[clueIndexes[clueIndex]].clueText = newClueText;
        setClues({ ...clues })
        setLocalStorage("clues", clues)
    }

    const handleSetClueDescription = (clueIndex, newClueDescription) => {
        clues[clueIndexes[clueIndex]].clueDescription = newClueDescription;
        setClues({ ...clues })
        setLocalStorage("clues", clues)
    }

    const areAllRequiredFieldsSubmitted = () => {
        let arecluesAndDescriptionsFilledIn = true
        for (const [, val] of Object.entries(clues)) {
            if (!val.clueText || !val.clueDescription) {
                arecluesAndDescriptionsFilledIn = false
            }
        }
        return arecluesAndDescriptionsFilledIn && question && riddleWord && isBoxChecked
    }

    const handleSubmitPuzzle = () => {
        if (!areAllRequiredFieldsSubmitted() || isSubmitButtonClicked) {
            return
        }
        setIsSubmitButtonClicked(true)
        const puzzleData = {
            question,
            riddleWord,
            puzzleAuthor,
            clues,
            clueIndexes
        }
        createPuzzle(puzzleData, (result) => {
            if (result.success) {
                setIsPuzzleSuccessfullySubmitted(true)
                localStorage.clear();
                setTimeLeft(5)
                const { puzzleId } = result.data
                setTimeout(() => {
                    navigate(`/puzzle/${puzzleId}?newSubmition=true`);
                }, 5000);
            }
            else {
                setIsPuzzleSuccessfullySubmitted(false)
            }
        })
    }

    const onDragEnd = result => {

        const { destination, source, draggableId } = result

        if (!destination) { return }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return
        }

        const newClueIndexes = Array.from(clueIndexes)
        newClueIndexes.splice(source.index, 1)
        newClueIndexes.splice(destination.index, 0, draggableId)

        setClueIndexes([...newClueIndexes])
    };

    const getSubmitButtonClassName = () => {
        if (isPuzzleSuccessfullySubmitted) {
            return "submit-puzzle-button success"
        } else if (isPuzzleSuccessfullySubmitted === false) {
            return "submit-puzzle-button failure"
        } else if (isSubmitButtonClicked) {
            return "submit-puzzle-button clicked"
        } else if (areAllRequiredFieldsSubmitted()) {
            return 'submit-puzzle-button'
        } else {
            return 'submit-puzzle-button disabled'
        }
    }

    const getSubmitButtonText = () => {
        if (isPuzzleSuccessfullySubmitted) {
            return "Puzzle submitted"
        } else if (isPuzzleSuccessfullySubmitted === false) {
            return "Puzzle Error"
        } else if (isSubmitButtonClicked) {
            return "Creating puzzle..."
        } else {
            return "Submit puzzle"
        }
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Navbar />
            <Droppable droppableId={"2"}>
                {provided => (
                    <div className='grid-center' ref={provided.innerRef} {...provided.droppableProps}>
                        <div className='flex-center'>
                            <div className='instructions-container'>
                                <p className='instructions-header'>Let's make a puzzle!</p>
                                <p className='instruction-text'><span className='instruction-number'>1.</span> Before starting your puzzle, make sure to read our <span onClick={() => navigate("/guidelines")} className='link'>Puzzle Creation Guidelines</span></p>
                                <p className='instruction-text'><span className='instruction-number'>2.</span>Select your riddle question.</p>
                                <p className='instruction-text'><span className='instruction-number'>3.</span> Write your riddle word, clues, and clue descriptions in the text boxes below.</p>
                                <p className='instruction-text'><span className='instruction-number'>4.</span> Drag and drop your clues to change their ordering.</p>
                                <p className='instruction-text'><span className='instruction-number'>5.</span> After you've finalized your puzzle, scroll to the bottom of the page to submit!</p>
                            </div>
                        </div>
                        <div className='flex-center'>
                            <div className='question-container'>
                                <div className='flex-center'>
                                    <p className='question-header select-riddle-question'>Select your riddle question.</p>
                                    <FontAwesomeIcon className="asterisk-icon" icon={faAsterisk} />
                                </div>
                                <div
                                    style={{ marginTop: 40 }}
                                    className='question-container-flex'>
                                    <button
                                        onClick={() => {
                                            setQuestion("WHERE")
                                            setLocalStorage("question", "WHERE")
                                        }}
                                        className={question === 'WHERE' ? 'question-type selected' : 'question-type'}>
                                        Where am I?
                                    </button>
                                    <button
                                        onClick={() => {
                                            setQuestion("WHAT")
                                            setLocalStorage("question", "WHAT")
                                        }}
                                        className={question === 'WHAT' ? 'question-type selected' : 'question-type'}>
                                        What am I?
                                    </button>
                                    <button
                                        onClick={() => {
                                            setQuestion("WHO")
                                            setLocalStorage("question", "WHO")
                                        }}
                                        className={question === 'WHO' ? 'question-type selected' : 'question-type'}>
                                        Who am I?
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='flex-center'>
                            <div className='question-container'>
                                <div className='flex-center'>
                                    <p className='question-header'>Write your riddle word.</p>
                                    <FontAwesomeIcon className="asterisk-icon" icon={faAsterisk} />
                                </div>
                                <input
                                    value={riddleWord}
                                    onChange={(e) => {
                                        setRiddleWord(e.target.value)
                                        setLocalStorage("riddleWord", e.target.value)
                                    }}
                                    onKeyDown={(e) => e.code === 'Space' || !allowedRiddleWordCharacters.includes(e.code) ? e.preventDefault() : null}
                                    maxLength={15}
                                    className='riddle-word-input' />
                            </div>
                        </div>
                        {[...Array(12).keys()].map((index) => (
                            <Clue
                                handleSetClue={handleSetClue}
                                key={clueIndexes[index]}
                                index={index}
                                clueText={clues[clueIndexes[index]].clueText}
                                clueDescription={clues[clueIndexes[index]].clueDescription}
                                id={clueIndexes[index]} />
                        ))}
                        {provided.placeholder}
                        <div className='flex-center'>
                            <div className='submission-container'>
                                <div
                                    style={{ gap: 20 }}
                                    className='flex-center'>
                                    <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                    <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                    <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                    <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                    <p className='instructions-header'>Submission Rules and Instructions </p>
                                    <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                    <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                    <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                    <FontAwesomeIcon className="caution-icon" icon={faTriangleExclamation} />
                                </div>
                                <p
                                    style={{ fontWeight: 500, fontSize: 21, marginTop: 5 }}
                                    className='instruction-text-submition text-submission-title'>
                                    How Submission Works
                                </p>
                                <p className='instruction-text-submition'><span className='instruction-number'>1.</span>
                                    When you submit your puzzle, you may continue to edit it until your puzzle is <i>Queued.</i>
                                </p>
                                <p className='instruction-text-submition'><span className='instruction-number'>2.</span>
                                    You are encouraged to enhance your clues if something pops into your head after submission!
                                </p>
                                <p className='instruction-text-submition'><span className='instruction-number'>3.</span>
                                    Once your puzzle is <i>Queued</i>, it will be locked (no more edits) and given a release date.
                                </p>
                                <p className='instruction-text-submition'><span className='instruction-number'>5.</span>
                                    Once the release date comes, your puzzle will be available for everyone to play!
                                </p>
                                <p
                                    style={{ fontWeight: 500, fontSize: 21, marginTop: 5 }}
                                    className='instruction-text-submition text-submission-title'>
                                    Submission Rules
                                </p>
                                <p
                                    className='instruction-text-submition'>
                                    Your puzzle must abide by the <span className='indent'>following to get accepted:</span>
                                </p>
                                <p className='instruction-text-submition'><span className='instruction-number'>1.</span>
                                    Have no harmful or offensive <span className='mobile-indent'>language.</span>
                                </p>
                                <p className='instruction-text-submition'><span className='instruction-number'>2.</span>
                                    Follow the <span onClick={() => navigate("/guidelines")} className='link'>Puzzle Creation <span className='mobile-indent'>Guidelines </span> (mentioned above).</span>
                                </p>
                                <div
                                    style={{ gap: 10 }}
                                    className='flex-center checkbox-container'>
                                    <input
                                        onChange={() => setIsBoxChecked(!isBoxChecked)}
                                        type="checkbox"
                                        className='checkbox' />
                                    <div style={{ display: "flex" }}>
                                        <p className='checkbox-text'> I have read through the <span onClick={() => navigate("/guidelines")} className='link'>Puzzle Creation Guidelines</span> and ensured my puzzle follows its instructions.
                                            <br></br>
                                            <span className='checkbox-text-second-line'>I understand that if my puzzle does not follow the guidelines it may not be approved.</span>
                                        </p>
                                        <FontAwesomeIcon className="asterisk-icon-checkbox" icon={faAsterisk} />
                                    </div>
                                </div>
                                <div>
                                    <p className='name-instructions'>
                                        If you would like your name to appear on your puzzle, please write your name below.
                                    </p>
                                    <input
                                        value={puzzleAuthor}
                                        onChange={(e) => {
                                            setPuzzleAuthor(e.target.value)
                                            setLocalStorage("puzzleAuthor", e.target.value)
                                        }}
                                        maxLength={50}
                                        placeholder={isMobile ? "Write your name here!" : "Write your name here! Otherwise the puzzle author will remain anonymous."}
                                        className='name-input' />
                                </div>
                                <button
                                    onClick={handleSubmitPuzzle}
                                    className={getSubmitButtonClassName()}>
                                    {getSubmitButtonText()}
                                </button>
                                {isPuzzleSuccessfullySubmitted ?
                                    <div>
                                        <FontAwesomeIcon className="checkmark-icon" icon={faCheckCircle} />
                                        <p className='submit-success-message'>Your puzzle was successfully submitted!</p>
                                        <p>You will be routed to your puzzle page in <b>{timeLeft ? timeLeft : null}</b></p>
                                    </div> : null}
                                {isPuzzleSuccessfullySubmitted === false ?
                                    <div>
                                        <FontAwesomeIcon className="xmark-icon" icon={faXmarkCircle} />
                                        <p className='submit-success-message'>There was an error submitting your puzzle</p>
                                        <p>Please reload the page and resubmit (your work will be saved).</p>
                                    </div> : null}
                            </div>
                        </div>
                    </div>
                )}
            </Droppable>
            <Footer />
        </DragDropContext>
    );
}

export default CreatePuzzle